<template>
    <v-container grid-list-xl fluid>
        <v-layout wrap class="mb-12">
            <v-flex xs12 v-if="details">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title class="text-h5">
                            {{ chargerName }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Carregador</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="details.board" @click="$router.push({ path: `/board/${details.board.name}` });">
                    <v-list-item-icon>
                        <v-icon>local_offer</v-icon>
                    </v-list-item-icon>
                    <v-list-item-subtitle><strong>Gateway: </strong>{{ details.board.name }}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item v-if="chargerData && chargerData.lastNfc">
                    <v-list-item-icon>
                        <v-icon>nfc</v-icon>
                    </v-list-item-icon>
                    <v-list-item-subtitle>
                        <strong>Último NFC: </strong>{{ chargerData.lastNfc }}
                    </v-list-item-subtitle>
                    <v-list-item-action>
                        <v-btn small style="width: 100%" color="success" class="ma-2 white--text" @click="copy(chargerData.lastNfc)" v-if="chargerData.lastNfc">
                            Copiar
                            <v-icon right dark small> content_copy </v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="details.board && details.board.name && configAdvanced && configAdvanced.server">
                    <v-list-item-icon>
                        <v-icon>vpn_lock</v-icon>
                    </v-list-item-icon>
                    <v-list-item-subtitle>
                        <ButtonVPN :UUID="details.board.name" :server="configAdvanced.server" />
                    </v-list-item-subtitle>
                </v-list-item>
                <v-list-item v-if="chargerData">
                    <v-list-item-subtitle>
                        <strong>Última Comunicação do gateway: </strong>{{ chargerData.keepAlive }}
                    </v-list-item-subtitle>
                </v-list-item>
                <v-list-item v-if="chargerData && chargerData.communication">
                    <v-list-item-subtitle>
                        <strong>Comunicação RS-485 gateway : </strong>{{ chargerData.communication ? "OK" : "Erro" }}
                    </v-list-item-subtitle>
                </v-list-item>
            </v-flex>

            <v-flex xs12>
                <v-progress-linear progress color="primary" :value="timerProgress" />
            </v-flex>

            <v-flex xs12 v-if="configAdvanced">
                <v-btn small v-for="index in configAdvanced.numberPlugs" :key="index" style="width: 100%" color="blue-grey" class="white--text my-1" :href="linkNowPlug(index)"
                    target="_blank">
                    <v-icon left>link</v-icon>
                    Plug {{ index }}
                </v-btn>
            </v-flex>

            <v-flex xs12 v-if="config && configAdvanced">
                <ExpandPanelSimple title="Comandos">
                    <v-layout wrap v-if="configAdvanced.protocol == '1.6'">
                        <v-flex xs12 class="text-right">
                            <strong>OCPP 1.6: </strong>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="reset('Hard')"> Hard Reset </v-btn>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="reset('Soft')"> Soft Reset </v-btn>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="close()"> Fechar Socket </v-btn>
                        </v-flex>
                        <v-flex xs12 class="text-right" v-for="index in configAdvanced.numberPlugs" :key="index">
                            <strong>Plug {{ index }}: </strong>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="remoteStart(index)"> RemoteStart </v-btn>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="remoteStop(index)"> RemoteStop </v-btn>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="changeAvailability('Inoperative', index)"> Inoperative </v-btn>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="changeAvailability('Operative', index)"> Operative </v-btn>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="unlockConnector(index)"> Unlock Connector </v-btn>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="setProfile(index, 1, 600)"> Set Profile </v-btn>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="triggerMessage(index, 'MeterValues')"> MeterValues </v-btn>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="triggerMessage(index, 'StatusNotification')"> StatusNotification </v-btn>
                        </v-flex>
                    </v-layout>

                    <Command :UUID="details.board.name" :operate="configAdvanced.operate" :server="configAdvanced.server" v-if="details.board" />

                </ExpandPanelSimple>
            </v-flex>

            <v-flex xs12 v-if="isAdmin && details && details.price">
                <ExpandPanelSimple title="Pagamento">
                    <v-alert border="left" class="my-0" colored-border color="error accent-4" elevation="4">
                        <h5 class="error--text">Pagar.me</h5>
                        <v-layout wrap>
                            <v-flex sm4 xs12>
                                <v-btn color="error" :small="$vuetify.breakpoint.smAndDown" class="mx-1 white--text" @click="clearAllPayment" style="width: 100%;">
                                    Limpar todos pagamentos
                                </v-btn>
                            </v-flex>
                            <v-flex sm4 xs12>
                                <v-btn color="warning" :small="$vuetify.breakpoint.smAndDown" class="mx-1 white--text" @click="clearPayment" style="width: 100%;">
                                    Limpar {{ chargerName }}
                                </v-btn>
                            </v-flex>
                            <v-flex sm4 xs12>
                                <v-btn color="warning" :small="$vuetify.breakpoint.smAndDown" class="mx-1 white--text" @click="startPayment" style="width: 100%;">
                                    Forçar inicio de cobrança
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-alert>
                    <PaymentOptions :charger="configAdvanced" :chargerName="chargerName" :price="details.price" plug="1" v-if="chargerName" />
                </ExpandPanelSimple>
            </v-flex>

            <v-flex xs12 v-if="details">
                <ExpandPanelSimple title="Mais informações">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="text-h5">
                                {{ details.UUID }}
                            </v-list-item-title>
                            <v-list-item-subtitle>Identificação</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>cable</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle><strong>Modelo: </strong>{{ details.model }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>calendar_today</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle><strong>Última modificação: </strong>{{ details.modifiedIn }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>calendar_today</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle><strong>Data Cadastro: </strong>{{ details.createdIn }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item v-if="details.pricePolicy">
                        <v-list-item-icon>
                            <v-icon>policy</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle><strong>Política de cobrança: </strong>{{ details.pricePolicy }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item v-if="details.price">
                        <v-list-item-icon>
                            <v-icon>price_check</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle><strong>Preço: </strong>R$ {{ details.price }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item v-if="details.priceKwh">
                        <v-list-item-icon>
                            <v-icon>price_change</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle><strong>Preço por kWh: </strong>R$ {{ details.priceKwh }}</v-list-item-subtitle>
                    </v-list-item>
                </ExpandPanelSimple>
            </v-flex>

            <v-flex xs12 v-if="config">
                <ExpandPanelSimple title="Configurações">
                    <h2 class="primary--text mt-4">Geral</h2>
                    <v-divider class="mb-12 red" style="opacity: 0.22" />

                    <CarConfiguration :data="config" @saved="close" />

                    <h2 class="primary--text mt-12">Cadastro</h2>
                    <v-divider class="mb-12 red" style="opacity: 0.22" />

                    <ChargerForm v-if="isAdmin && configAdvanced" :itemToEdit="configAdvanced" :lock="false" @save="init" controlAPI />
                </ExpandPanelSimple>
            </v-flex>
        </v-layout>
        <ConfirmModal ref="confirm" />
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import ConfirmModal from "@/components/core/ConfirmModal";
import Command from "@/components/shared/Command";
import ButtonVPN from "@/components/shared/ButtonVPN";
import ExpandPanelSimple from "@/components/core/ExpandPanelSimple.vue";
import PaymentOptions from "@/components/shared/Payment/PaymentOptions";
import CarConfiguration from "@/components/form/CarConfiguration";
import ChargerForm from "@/components/form/Charger";

export default {
    name: "ChargerView",

    components: { PaymentOptions, CarConfiguration, ExpandPanelSimple, ChargerForm, ConfirmModal, Command, ButtonVPN },

    data() {
        return {
            isAdmin: false,
            details: null,
            chargerData: null,
            chargerName: null,
            config: null,
            configAdvanced: null,
            timer: null,
            timerProgress: 100,
        };
    },

    mounted() {
        this.isAdmin = this.verifyRole("ROLE_ADMIN");
        if (this.$route.params.name) {
            this.chargerName = this.$route.params.name;
        }
        this.init();
        this.update(false);
    },

    created() {
        this.timer = setInterval(this.timerTick, 100);
    },

    computed: {
        ...mapGetters(["verifyRole"]),
    },

    methods: {
        linkNowPlug(plug) {
            return `${window.location.origin}/now/${this.chargerName}/${plug}`;
        },
        copy(text) {
            navigator.clipboard.writeText(text);
            this.$eventHub.$emit("msgSuccess", `Código copiado!!!`);
        },
        timerTick() {
            if (this.timerProgress > 0) {
                this.timerProgress--;
            } else {
                this.update();
                this.timerProgress = 100;
            }
        },
        async init() {
            if (this.chargerName) {
                await this.$http
                    .get(`api/v3/charger/configuration/${this.chargerName}`)
                    .then((result) => {
                        this.details = result;
                    })
                    .catch(() => {
                        this.$eventHub.$emit("msgError", "Erro ao buscar informações.");
                    });
                if (this.details && this.details.id) {
                    await this.$http
                        .get(`api/v3/car/configuration/${this.details.id}`)
                        .then((result) => {
                            this.config = result;
                        })
                    if (this.isAdmin)
                        await this.$http
                            .get(`api/v2/charger/${this.details.id}`)
                            .then((result) => {
                                this.configAdvanced = result;
                            })
                }
            }
        },
        async update(continuous = true) {
            if (this.chargerName) {
                await this.$http
                    .get(`api/v3/charger/details/${this.chargerName}`, { continuous })
                    .then((result) => {
                        this.chargerData = result;
                    }).catch(() => {
                        this.$eventHub.$emit("msgError", "Erro ao buscar informações.");
                    });
            }
        },
        sendCommand(url, parameters) {
            if (this.configAdvanced && this.configAdvanced.server) {
                this.$http
                    .postDynamic(this.configAdvanced.server, url, parameters)
                    .then((result) => {
                        this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Enviado com sucesso.");
                    }).catch((error) => {
                        this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao enviar, tente novamente.");
                    });
            } else {
                this.$eventHub.$emit("msgError", "Falta configuração do servidor.");
            }
        },
        confirmAndSent(msg, url, parameters) {
            this.$refs.confirm.open("Confirma?", msg).then((confirm) => {
                if (confirm) this.sendCommand(url, parameters);
            });
        },
        remoteStop(plug) {
            this.confirmAndSent("Deseja realmente parar o carregamento?", "api/v2/ocpp/remoteStop", { charger: this.chargerName, plug });
        },
        remoteStart(plug) {
            this.confirmAndSent("Deseja realmente desbloquear o carregador?", "api/v2/ocpp/remoteStart", { charger: this.chargerName, plug });
        },
        triggerMessage(plug, requestedMessage) {
            this.confirmAndSent("Deseja realmente forçar uma requisição de mensagem?", "api/v2/ocpp/triggerMessage", { charger: this.chargerName, plug, requestedMessage });
        },
        unlockConnector(plug) {
            this.confirmAndSent("Deseja realmente desbloquear o carregador?", "api/v2/ocpp/unlockConnector", { charger: this.chargerName, plug: plug });
        },
        changeAvailability(type, plug) {
            this.confirmAndSent("Deseja realmente alterar disponibilidade do carregador?", "api/v2/ocpp/changeAvailability", { charger: this.chargerName, plug: plug, type: type });
        },
        reset(type) {
            this.confirmAndSent("Deseja realmente resetar o carregador?", "api/v2/ocpp/reset", { charger: this.chargerName, type: type });
        },
        close() {
            this.confirmAndSent("Deseja realmente fechar o socket? Isso vai forçar a reconexão com novas configurações.", "api/v2/ocpp/close", { charger: this.chargerName });
        },
        setProfile(plug, power, time) {
            this.confirmAndSent("Deseja realmente alterar profile do carregador?", "api/v2/ocpp/setChargingProfile", { charger: this.chargerName, plug: plug, power: power, time: time });
        },
        clearAllPayment() {
            this.confirmAndSent("Deseja realmente limpar todos os pagamentos pendentes?", "api/v2/payment/command/pagarme", { charger: this.chargerName, action: "clearAll" });
        },
        clearPayment() {
            this.confirmAndSent("Deseja realmente limpar os pagamentos pendentes desse carregador?", "api/v2/payment/command/pagarme", { charger: this.chargerName, action: "clear" });
        },
        startPayment() {
            this.confirmAndSent("Deseja realmente criar um pagamento?", "api/v2/payment/command/pagarme", { charger: this.chargerName, plug: 1, action: "start" });
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>
